@import "src/styles/colors";
@import "src/styles/variables";

.sider {
  z-index: 101;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  text-align: center;
  min-width: 240px !important;

  :global {
    .ant-menu {
      height: calc(100% - 120px);
      overflow-y: auto;
    }
    .ant-menu-item-selected {
      article {
        color: white;
      }
    }
    .ant-menu-item {
      text-align: left;
      padding: 25px 15px !important;
      &:hover {
        article {
          color: white;
        }
      }
    }
    article {
      color: rgb(190, 190, 190);
      font-weight: bold;
    }
  }
}

.logo {
  background-color: #2b2f3a;
  padding: 30px 40px;
  cursor: pointer;
  margin-bottom: 20px;
}

.iconCollapse {
  position: absolute;
  left: 25px;
  top: 32px;
}
