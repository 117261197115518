@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot");
  src: url("../assets/fonts/icomoon.eot") format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf") format("truetype"),
    url("../assets/fonts/icomoon.woff") format("woff"),
    url("../assets/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  a {
    font-family: var(--font-family);
  }
}

// menu
.icon-people:before {
  content: "\e900";
}

.icon-setting:before {
  content: "\e901";
}
