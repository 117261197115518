@import "src/styles/colors";
@import "src/styles/variables";

.root {
  background-color: $bg-white;
  min-height: 100vh;
}

.footer {
  padding: 17px !important;
  text-align: center;
  border-top: 1px solid $mono-gray-20;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  font-weight: $font-weight-700;
  font-size: $font-size-10;
}
