@import "src/styles/variables";
@import "src/styles/colors";
@import "src/styles/mixins";

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.no-wrap-hidden {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.white-space-pre {
  white-space: pre;
}

// flex
.d-flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center !important;
}

.flex-align-end {
  display: flex;
  align-items: flex-end !important;
}

.flex-start {
  display: flex;
  align-items: flex-start !important;
}

.flex-space-between {
  display: flex;
  justify-content: space-between !important;
}

.flex-space-around {
  display: flex;
  justify-content: space-around !important;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly !important;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start !important;
}

.flex-justify-center {
  display: flex;
  justify-content: center !important;
}

.flex-justify-end {
  display: flex;
  justify-content: flex-end !important;
}

.flex-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-none {
  display: none !important;
}

// Fonts
@for $size from 1 through 50 {
  .font-#{$size} {
    font-size: #{$size}px !important;
  }
}

// Text Aligns
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-end {
  text-align: end !important;
}

.text-left {
  text-align: left !important;
}

// Font weight
.font-weight-regular {
  font-weight: $font-weight-400 !important;
}

.font-weight-bold {
  font-weight: $font-weight-700 !important;
}

.font-weight-medium {
  font-weight: $font-weight-500 !important;
}

//Borders
.no-border {
  border: none !important;
}

// Spaces
$spaces: (
  "auto": "auto",
);
@for $value from 0 through 100 {
  $spaces: map-merge(
    $spaces,
    (
      $value: $value * 4px,
    )
  );
}

//gap
@for $gap from 1 through 50 {
  .gap-#{$gap} {
    gap: #{$gap}px !important;
  }
}

// line height
$line-height-list: 18, 20, 21, 22, 24, 32;
@each $height in $line-height-list {
  .line-height-#{$height} {
    line-height: #{$height}px !important;
  }
}

// Margins
@each $key, $value in $spaces {
  .m-#{$key} {
    margin: #{$value} !important;
  }

  .mt-#{$key} {
    margin-top: #{$value} !important;
  }

  .mr-#{$key} {
    margin-right: #{$value} !important;
  }

  .mb-#{$key} {
    margin-bottom: #{$value} !important;
  }

  .ml-#{$key} {
    margin-left: #{$value} !important;
  }

  .my-#{$key} {
    margin-top: #{$value} !important;
    margin-bottom: #{$value} !important;
  }

  .mx-#{$key} {
    margin-left: #{$value} !important;
    margin-right: #{$value} !important;
  }
}

// Paddings
@each $key, $value in $spaces {
  .p-#{$key} {
    padding: #{$value} !important;
  }

  .pt-#{$key} {
    padding-top: #{$value} !important;
  }

  .pr-#{$key} {
    padding-right: #{$value} !important;
  }

  .pb-#{$key} {
    padding-bottom: #{$value} !important;
  }

  .pl-#{$key} {
    padding-left: #{$value} !important;
  }

  .py-#{$key} {
    padding-top: #{$value} !important;
    padding-bottom: #{$value} !important;
  }

  .px-#{$key} {
    padding-left: #{$value} !important;
    padding-right: #{$value} !important;
  }
}

$maxWidths: 100, 200, 400, 600, 800;
@each $value in $maxWidths {
  .mw-#{$value} {
    max-width: #{$value}px !important;
  }
}

/* Colors */
$colors: (
  "white": $text-white,
  "black": black,
  "red": #ff0505,
  "blue" : #001adf,
  "gray-40": $mono-gray-40,
);

// Text colors
@each $key, $value in $colors {
  .text-#{$key} {
    color: #{$value} !important;
  }
}

// Bg colors
@each $key, $value in $colors {
  .bg-#{$key} {
    background-color: #{$value} !important;
  }
}

// Cursor
.cursor-pointer {
  cursor: pointer !important;
}

/* Positions */
$positions: "static", "relative", "absolute", "fixed", "sticky";

@each $position in $positions {
  .position-#{$position} {
    position: #{to-lower-case(#{$position})} !important;
  }
}

.position-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// text-decoration
.text-underline {
  text-decoration: underline !important;
}

//width
.width-fit-content {
  width: fit-content !important;
}

.full-width {
  width: 100% !important;
}

// height
.full-height {
  height: 100vh !important;
}

//border
.border-solid {
  border: 1px solid;
  padding: 0 8px;
}

//text-overflow
.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
}

// float
.float-left {
  float: left;
}
.float-right {
  float: right;
}

// Text break word
.break-word {
  overflow-wrap: break-word;
}

.transformY-center {
  top: 50%;
  transform: translateY(-50%);
}

.right-0 {
  right: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}