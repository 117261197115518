@import "src/styles/colors";
@import "src/styles/variables";

.button {
  &.noPadding {
    padding-left: 0;
    padding-right: 0;
  }
  font-weight: 500;
}
