@import "src/styles/colors";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(5, 5, 5, 0.2);
  z-index: 1000;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  &.isFitContent {
    background-color: unset;
    width: 100%;
    position: relative;
    height: auto;
    padding: 20px;
  }
}
