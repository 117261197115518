$text-white: #fff;
$text-disabled: #f5f5f5;

$bg-main: #f6e19f;
$bg-white: #fff;
$bg-gray: #f2f2f2;

$border-input: #e0e0e0;

$primary-main: #007bff;
$primary-dark: #0032fc;

$mono-gray-80: #5a6268;
$mono-gray-60: #6c757d;
$mono-gray-40: #858d94;
$mono-gray-20: #dee2e6;

$link-blue: #365fff;
$light-black: #212529;
