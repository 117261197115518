@import "src/styles/colors";
@import "src/styles/variables";

.pagination {
  :global {
    .ant-pagination-item {
      border-color: $mono-gray-20;
    }
    .ant-pagination-options {
      display: none !important;
    }
  }
}
