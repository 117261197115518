@import "src/styles/colors";
@import "src/styles/variables";

.root {
  overflow-y: hidden;
  height: 100vh;
  :global {
    .ant-layout-sider {
      overflow-x: hidden;
    }
    .ant-layout-sider-collapsed {
      min-width: 0px !important;
    }
  }
}

.content {
  overflow-y: auto;
  padding: 20px 40px;
  margin-left: 240px;
  min-height: calc(100vh - 50px);
  background-color: $bg-gray;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  justify-content: end;
  font-weight: 500;
  color: white !important;
  text-transform: uppercase;
  :global {
    .ant-popover-open {
      outline-width: 5px !important;
    }
  }
}

.avatar {
  background-color: rgb(185, 185, 185);
  outline: solid 0px #ffffff24;
  transition: all 0.2s;
  &:hover {
    outline-width: 5px;
  }
}

.contentCollapsed {
  margin-left: 80px;
}
