@import "src/styles/colors";
@import "src/styles/variables";

@import "src/styles/common.module.scss";
@import "src/styles/icons.module.scss";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: auto;
  word-break: break-word;
  & > iframe {
    display: none;
  }
  margin: 0;
  padding: 0;
}

/* Force ant input number to take up all width when used inside col */
.ant-col {
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-tooltip-content .ant-typography {
  color: white;
}

// table
th.ant-table-cell {
  background-color: #fff !important;
}
.ant-table-thead {
  tr > th,
  td {
    background-color: rgb(215, 215, 215) !important;
    padding: 16px 16px !important;
  }
}
.ant-table-cell {
  padding: 4px 16px !important;
}
.ant-table-body {
  overflow: auto !important;
}

.indent-level-1 {
  padding-left: 40px !important;
}

.indent-level-2 {
  padding-left: 80px !important;
}

.indent-level-3 {
  padding-left: 100px !important;
}

// form
.ant-form-item-explain-error {
  font-size: 12px;
}
.ant-form-item-required {
  flex-direction: row-reverse;
  gap: 4px;
}
.ant-form-item-label {
  font-weight: 500;
}

// dialog
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.ant-picker-ranges{
  margin-top: 0;
  .ant-btn-sm {
    height: 30px !important;
  }
}
