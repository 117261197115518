.root {
  :global {
    .ant-upload .ant-btn {
      min-width: 300px;
    }

    .ant-upload-list-item {
      width: 300px;
    }
  }
}
